import { cloneElement, useState } from "react";

const CopyClipboard = ({copyText,children}) => {
  const [isCopied,setIsCopied]=useState(false);
  
    
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
      }
      
      const handleCopyClick = () => {
        copyTextToClipboard(copyText?.querySelector("[contenteditable]")?.innerText)
          .then(() => {
            setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
  return (
    cloneElement(
        <div className="position-relative copy-text">
            {children}
            <span className={`position-absolute  fs-small-grey text-nowrap text-copy ${isCopied?"active":""}`}>Text copied</span>
        </div>
        ,{onClick: handleCopyClick })
  )
}

export default CopyClipboard