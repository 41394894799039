import { cloneElement, createContext, useContext, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutSide";

const DropDownContext=createContext();

export const DropDown = ({children}) => {
    const [open,setOpen]=useState(false);
    const selectContainerRef = useRef(null);
    const clickOutsideHandler = () => setOpen(false);
    useOnClickOutside(selectContainerRef,clickOutsideHandler);
    
    return (
        <div className='position-relative' ref={selectContainerRef}>
          <DropDownContext.Provider value={{open,setOpen}}>
                {children}
          </DropDownContext.Provider>
        </div>
    )
}

function Header(){
    const {open,setOpen}=useContext(DropDownContext);
    return (
          <button type="button" className="more-btn dropdown-toggle"
           onClick={()=>setOpen(!open)} >
            <svg
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          </button>
    )
}

function Body({value}){
    const {open,setOpen}=useContext(DropDownContext);
    return (
        <ul className={`dropdown-menu custom-drop ${open?"d-block":""}`} >
            {value.map((item,idx)=>{
         return <li key={idx}>
           <button
             type="button"
             className='d-flex align-items-center justify-content-between px-3 w-100'
             onClick={() =>{setOpen(!open)}}
           >
             <p className="fs-medium">{item.name}</p> 
           </button>
         </li>

            })}
       </ul>
    )
}

function HeaderWithContent({children}){
    const {open,setOpen}=useContext(DropDownContext);
    const toggleHeaderFun=(e)=>{
        setOpen(!open)
    }
    return cloneElement(children,{onClick: toggleHeaderFun });
}

function BodyWithContent({children}){
  const {open}=useContext(DropDownContext);
  return cloneElement(children,{className:`${children.props.className} ${open?"d-block":""}`})

}

DropDown.Header=Header;
DropDown.Body=Body;
DropDown.HeaderWithContent=HeaderWithContent;
DropDown.BodyWithContent=BodyWithContent;