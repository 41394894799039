import { useState } from "react";

const Sidebar = ({userDetails,activeUser,setActiveUser}) => {
  const [topic, setTopic] = useState([
    { item: "Recent", value: 8 },
    { item: "Open", value: 12 },
    { item: "All", value: 23 },
  ]);
  const [toggle,setToggle]=useState("Recent");

  return (
    <aside className="sidebar-con ">
      <div className="d-flex align-items-center justify-content-between topic-con">
        <div className="d-flex align-items-center gap-2">
          {topic.map((item, idx) => {
            return (
              <button
                type="button"
                className={`topic-btn ${toggle==item.item ? "active" : null}`}
                key={idx}
                onClick={()=>setToggle(item.item)}
              >
                {item.item}({item.value})
              </button>
            );
          })}
        </div>
        <div className="d-flex align-items-center gap-2 img-con">
          <button type="button">
            <img src={require("../assets/images/search.png")} alt="" />
          </button>
          <button type="button">
            <img src={require("../assets/images/filter.png")} alt="" />
          </button>
        </div>
      </div>
      <div className="sidebar-card-con scroll-bar hide-track">
        {userDetails.map((item, idx) => {
          return (
            <div
            onClick={()=>setActiveUser(item.id)}
             className={`sidebar-card rounded-2 ${activeUser===item.id?"active":null}`} key={idx}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="sidebar-topic d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <p>
                      <span className="fs-topic-primary-bold">CID</span>
                      <span className="fs-topic-primary-regular">
                        {item.id}
                      </span>
                    </p>
                    <p
                      className={`${
                        item.timeType === "New" ? null : "progress"
                      } identify-text-blue fs-small-light`}
                    >
                      {item.timeType}
                    </p>
                  </div>
                </div>
                <p
                  className={`fs-regular-semibold text-notify-red ${item.type}`}
                >
                  {item.type}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="fs-small-grey">
                  Submitted: 07 Mar 2024 | 03:30pm
                </p>
                <p className="fs-regular-semibold text-end">{item.issue}</p>
              </div>

              <div className="user-con d-flex align-items-center gap-2">
                <img
                  src={require(`../assets/images/${item.img}.png`)}
                  className="rounded-circle overflow-hidden"
                  alt=""
                />
                <div>
                  <p className="fs-topic-medium user-name">{item.name}</p>
                  <p className="fs-small-grey">
                    Last Activity: 20 Oct 2023 10:30pm
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-2">
                <div>
                  <p className="fs-small-italic-grey">Assigned To</p>
                  <p className="fs-medium">{item.assign}</p>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="d-flex align-items-center gap-1"
                  >
                    <img
                      src={require("../assets/images/redesign.png")}
                      className="reassign-icon"
                      alt=""
                    />
                    <p className="fs-small-light">Reassign</p>
                  </button>
                  <button
                    type="button"
                    className="d-flex align-items-center gap-1"
                  >
                    <img
                      src={require("../assets/images/delete1.png")}
                      className="close-icon"
                      alt=""
                    />
                    <p className="fs-small-light">Close</p>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default Sidebar;
