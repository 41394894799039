import { cloneElement } from "react";

const DownloadText = ({children,text}) => {
    const downloadTxtFile = () => {
        const element = document.createElement("a");
        element.classList.add("d-none")
        const file = new Blob([text?.querySelector("[contenteditable]")?.textContent], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        element.remove();
      }
  return (
     cloneElement(children,{onClick: downloadTxtFile })
  )
}

export default DownloadText