
const Header = () => {
  return (
    <header className="d-flex align-items-center justify-content-end w-100">
        <div className="log-con header-sec d-flex align-items-end flex-column">
            <img src={require(`../assets/images/logo.png`)} alt="" />
            <p className="log-text">NEO DESK</p>
        </div>
        <div className="d-flex align-items-center flex-column user-sec">
           <img src={require(`../assets/images/profile.png`)} alt="" />
            <p className="fs-medium">Sami Zahran</p>
        </div>
    </header>
  )
}

export default Header