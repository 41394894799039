import { cloneElement, createContext, useContext, useState } from "react"
import { createPortal } from "react-dom";

const ModalContext=createContext();
const Modal = ({children}) => {
    const [isOpen,setIsOpen]=useState(false);
  return (
    <ModalContext.Provider value={{isOpen,setIsOpen}}>
        {children}
    </ModalContext.Provider>
  )
}

const ToggleButton = ({children}) => {
  const {setIsOpen}=useContext(ModalContext);
  return cloneElement(children,{onClick:()=>{setIsOpen(true)}})
}

 const Content = ({children}) => {
  const {isOpen,setIsOpen}=useContext(ModalContext);
    return <>
      <section className={`modal-section ${isOpen?"active":null}`} >
      <div className="modal-cust-body  h-100">
         {children}
      </div>
      </section>
     {isOpen&& <div className="cust-modal-backdrop " onClick={()=>setIsOpen(false)}> </div>}
      </>
 }

 const CloseIcon=({children})=>{
  const {setIsOpen}=useContext(ModalContext);
    return cloneElement(children,{onClick:()=>{setIsOpen(false)}})
 }

 Modal.ToggleButton=ToggleButton
 Modal.Content=Content
 Modal.CloseIcon=CloseIcon

export default Modal