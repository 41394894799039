import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";

import { Toolbar } from "./Components";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};

const RichTextExample = ({renderActionContent,contentEdit}) => {
  const [value, setValue] = useState([renderActionContent]);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  useEffect(()=>{
    let totalNodes = editor.children.length;
    for (let i = 0; i < totalNodes - 1; i++) {
      // console.log(i)
      Transforms.removeNodes(editor, {
          at: [totalNodes-i-1],
      });
  }
  Transforms.removeNodes(editor, {
    at: [0],
  });
    Transforms.insertNodes(editor, [renderActionContent], {
      at: [editor.children.length],
  });
  },[renderActionContent])
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  
  
  return (
    <Slate editor={editor} initialValue={value} value={value} onChange={value => setValue(value)}>
      {/* {console.log(value)} */}
      {contentEdit&&<Toolbar>
        <MarkButton format="bold" icon="B" />
        <MarkButton format="italic" icon="I" />
        <MarkButton format="underline" icon="U" />
      </Toolbar>}
      <Editable
        renderLeaf={renderLeaf}
        placeholder="Enter some rich text…"
        spellCheck={false}
        className={"edit-content fs-medium"}
        autoFocus={contentEdit}
        readOnly={!contentEdit}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};


const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};


const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};


const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <button
    style={{color:isMarkActive(editor, format) ? "black" : "#ccc"}}
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
        {icon}
    </button>
  );
};



export default RichTextExample;
