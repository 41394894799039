import { useEffect, useMemo, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { DropDown } from "../components/DropDown";
import Modal from "../components/Modal";
import ChipCon from "../components/ChipCon";
import CopyClipboard from "../components/CopyClipboard";
import DownloadText from "../components/DownloadText";
import Speech from "../components/Speech";
import LikeDislike from "../components/LikeDislike";
import ChatFeature from "../components/ChatFeature";
import RichTextExample from "../components/TextEditer/Index";

const Dashboard = () => {
  const baseURL='https://8637-125-16-42-34.ngrok-free.app/query';
  const actContent = [
    {
      index: "precious",
      type: "paragraph",
      children:[
        { text: "We regret to inform you that a suspicious transaction has been detected & we had holded the payment of" },
        { text: "2,50,000.00 AED", bold: true },
        { text: "In order to proceed with the verification process and release the payment, we kindly" },
        { text: "request your cooperation in providing additional information", bold: true },
        { text: "(attached form)" },
        { text: "regarding the transaction", bold: true },
        { text: "We appreciate your understanding and cooperation in this matter."},
      ]
    },
    {
      index: "long",
      type: "paragraph",
      children:[
        { text: "We regret to inform you that a suspicious transaction has been detected & we had holded the payment of" },
        { text: "2,50,000.00 AED", bold: true },
        { text: "In order to proceed with the verification process and release the payment, we kindly" },
        { text: "request your cooperation in providing additional information", bold: true },
        { text: "(attached form)" },
        { text: "regarding the transaction", bold: true },
        { text: "We appreciate your understanding and cooperation in this matter."},
        { text: "request your cooperation in providing additional information", bold: true },
        { text: "(attached form)" },
        { text: "regarding the transaction", bold: true },
        { text: "We appreciate your understanding and cooperation in this matter."},
      ]
    },
    {
      index: "casual",
      type: "paragraph",
      children:[
        { text: "We regret to inform you that a suspicious transaction has been detected & we had holded the payment of" },
        { text: "2,50,000.00 AED", bold: true },
      ]
    },
  ];

  const demoContentTrigger=()=>{
    setContentEdit(!contentEdit)
  }
  const [transactionDet, setTransactionDet] = useState([
    { name: "Transaction ID", value: "SBEI2138092819" },
    { name: "Transaction Date", value: "07 Mar 2024  10:00am" },
    { name: "Payer", value: "SunBright Exports Imports" },
    { name: "Payee", value: "Dutoo Logistics Corp." },
  ]);
  const [userDetails, setUserDetails] = useState([
    {
      id: 8801,
      img: "profile-1",
      timeType: "New",
      name: "Ahmad AI-Farsi",
      type: "High",
      issue: "Payment Issue",
      assign: "Sami Zahran",
    },
    {
      id: 9272,
      img: "profile-2",
      timeType: "New",
      name: "Jammal-Uddin",
      type: "Low",
      issue: "Service Feeback",
      assign: "Sami Zahran",
    },
    {
      id: 6191,
      img: "profile",
      timeType: "In progress",
      name: "Ahmad AI-Farsi",
      type: "Medium",
      issue: "Account Inquiry",
      assign: "Sami Zahran",
    },
    {
      id: 6147,
      img: "profile-1",
      timeType: "New",
      name: "Ahmad AI-Farsi",
      type: "High",
      issue: "Payment Issue",
      assign: "Sami Zahran",
    },
  ]);
  const [activeUser, setActiveUser] = useState();
  const [tab1, setTab1] = useState("actions");
  const [renderActionContent,setRenderActionContent]=useState(actContent[0]);
  const [contentEdit,setContentEdit]=useState(false);
  const [chat, setChat] = useState([
    { youSend: true, msg: "show  templates for payment transaction failure" },
    {
      youSend: false,
      msg: "Select the transaction type for which you need the template",
    },
  ]);
  const [action, setAction] = useState("");
  const [defineChat,setDefineChat]=useState("");
  const [userChatType,setUserChatType]=useState("");
  const [preview,setPreview]=useState("");
  const [fileUpload,setFileUpload]=useState("CID32479 Contact.xls");
  const actionContentRef=useRef(null);
  const [emailContent, setEmailContent] = useState(""); 
  const [ChatResponse, setChatResponse] = useState(""); 

  const ulElement=useRef();
  const chatRef=useRef();

  useEffect(() => {
    setActiveUser(userDetails[0].id);
  }, []);

  const user = useMemo(() => {
    return userDetails.filter((item, idx) => {
      return item.id === activeUser;
    })[0];
  }, [activeUser]);

  useEffect(()=>{
    if(defineChat.length>0){
      setChat([...chat,{
        youSend: true,
        msg: defineChat,
      } ])   
      handleChatResponse(defineChat)
      
    }
  },[defineChat]);

  useEffect(()=>{
    ulElement?.current?.scrollTo({
      top: ulElement?.current?.scrollHeight,
      behavior: "smooth",
    });
  },[chat]);

  const imageUpload=(e)=>{
    const reader=new FileReader();
    reader.onload=()=>{
       if(reader.readyState===2){
        setPreview(reader.result);
       }
      }
      if(e.target?.files){
       reader.readAsDataURL(e.target.files?.[0])
     }
  };

  const handleButtonClick = async () => {
    console.log("hello")
    try {
        // Customer name to send to the backend
        const customerName = 'Ahmad AI-Farsi';
        const customerEmail = 'ahmad@protonmail.ae'
        const queries = [
          `Compose an informative email for ${customerName}, including transaction details.`,
          `Prepare an email to ${customerName,customerEmail}.`,
          `Generate a detailed email for ${customerName}, outlining their transaction details.`,
          `Formulate an email to be sent to ${customerName}, providing them with transaction information .`,
          `Construct an email message for ${customerName} containing transaction.`,
          `Draft an email notifying ${customerName} of their transaction details.`,
          `Craft an email addressed to ${customerName} with limited transaction insights .`,
          `Compose an email to ${customerName} containing transaction.`,
          `Craft an email communication for ${customerName}.`
      ];

      // Randomly select a query from the list
      const randomIndex = Math.floor(Math.random() * queries.length);
      const randomQuery = queries[randomIndex];
        

        // Send request to the backend with the customer name
        const response = await fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "randomQuery" : randomQuery })
        });

        if (!response.ok) {
            throw new Error('Failed to get smart response');
        }

        const data = await response.text();
        console.log(data);
        // Setting the email content directly from the result
        setRenderActionContent({
          index: "precious",
          type: "paragraph",
          children:[
            { text: data },
          ]
        });

    } catch (error) {
        console.error('Error:', error);
    }
};

// Function to handle button click
const handleChatResponse = async (value) => {
  try {
      const response = await fetch(baseURL, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ "randomQuery" : value}) // Assuming query is the variable containing the input message
      });

      if (!response.ok) {
          throw new Error('Failed to get smart response');
      }

      // Get the response text
      const data = await response.text();
      console.log(data);
      setChat([...chat,{
        youSend: true,
        msg: value,
        img:preview
      },{
        youSend: false,
        msg: data,
      }])
      setUserChatType("") ;
      
      setDefineChat("")

  } catch (error) {
      console.error('Error:', error);
  }
};


const chatSubmit=(e)=>{
  e.preventDefault();
  setChat([...chat,{
    youSend: true,
    msg: userChatType,
    img:preview
  }])
  handleChatResponse(userChatType)
  setUserChatType("") ;
  chatRef.current.focus();setPreview("");
}
  
  return (
    <section className="d-flex dashboard-section">
      <Sidebar
        userDetails={userDetails}
        activeUser={activeUser}
        setActiveUser={setActiveUser}
      />
      <div className="flex-grow-1 main-con scroll-bar">
        <Header />
        <div className="d-flex align-items-center justify-content-between pt-4">
          <div>
            <div className="d-flex align-items-center gap-3">
              <h2 className="d-flex align-items-center">
                <p className="fs-main-topic-bold">CID</p>
                <p className="fs-main-topic-medium">{activeUser}</p>
              </h2>
              <DropDown>
                <DropDown.HeaderWithContent>
                  <button
                    type="button"
                    className="drop-new d-flex align-items-center "
                  >
                    <img
                      src={require("../assets/images/Backarrow.png")}
                      alt=""
                    />
                    <p className="fs-small-light">New</p>
                  </button>
                </DropDown.HeaderWithContent>
                <DropDown.Body
                  value={[{ name: "Low" }, { name: "Medium" }]}
                ></DropDown.Body>
              </DropDown>
            </div>
            <p className="fs-main-topic-bold">Payment Flagged as Suspicious!</p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <button type="button" className="res-close-con res-btn">
              <img src={require("../assets/images/redesign.png")} alt="" />
              <p className="fs-medium">Reassign</p>
            </button>
            <button type="button" className="res-close-con close-btn">
              <img src={require("../assets/images/delete1.png")} alt="" />
              <p className="fs-medium">Close</p>
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 pt-1">
          <p className="fs-medium payment-text">Payment Issues</p>
          <p className="fs-medium high-priority-text red-text">High Priority</p>
        </div>
        <div className="d-flex align-items-center gap-3 pt-1">
          <p className="fs-medium-italic-grey">Submitted :</p>
          <p className="fs-medium">07 Mar 2024 03:30 pm</p>
        </div>
        <div className="d-flex align-items-center gap-3 pt-1">
          <p className="fs-medium-italic-grey">Assigned to :</p>
          <p className="fs-medium blue-text">Sami Zahran</p>
        </div>
        <div className="w-100 d-flex align-items-center flex-column">
          {user?.img ? (
            <img
              src={require(`../assets/images/${user?.img}.png`)}
              className="main-user-img"
              alt=""
            />
          ) : null}
          <p className="fs-medium">Ahmad AI-Farsi</p>
          <p>
            <span className="fs-main-topic-medium">Cust ID :</span>
            <span className="ps-2 fs-main-topic-medium blue-text">
              238762300
            </span>
          </p>
          <p className="fs-regular-semibold text-end">
            <span>+971501234567 </span>
            <span className="p-2">|</span> <span>ahmad@protonmail.ae</span>
          </p>
        </div>
        <p className="case-text fs-main-topic-bold">CASE INFO</p>
        <div className="case-section d-flex align-items-start w-100">
          <div className="left-con">
            <p className="fs-medium-italic-grey">Amount</p>
            <div className="d-flex align-items-center gap-3">
              <p className="d-flex align-items-center">
                <span className="fs-main-topic-bold">250,000.00</span>
                <span className="fs-main-topic-medium">AED</span>
              </p>
              <p className="fs-medium">On Hold</p>
            </div>
            <p className="fs-small-grey">
              Suspicious transaction has been detected on the bank account. Our
              system has flagged a payment of 250,000 UED as potentially
              fraudulent, and as a precautionary measure, its placed on hold for
              further clarification.
            </p>
            <div className="d-flex align-items-center justify-content-between transaction-det-con">
              {transactionDet.map((item, idx) => {
                return (
                  <div key={idx}>
                    <p className="fs-medium">{item.name}</p>
                    <p className="transaction-value">{item.value}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="right-con">
            <p className="topic">Last Activity</p>
            <div className="activity-con scroll-bar">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="fs-medium">07 Mar 2024 03:31 pm</p>
                  <p className="fs-medium-italic-grey">
                    Call Initiated by Sami Zahran
                  </p>
                </div>
                <img
                  src={require("../assets/images/activity.png")}
                  className="note-img"
                  alt=""
                />
              </div>
              <div className="pt-3">
                <p className="fs-medium">07 Mar 2024 03:30pm</p>
                <p className="fs-medium-italic-grey blue-text">
                  Ticket submitted by Ahmad Al-Farsi regarding the payment on
                  hold
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="resolution-con">
          <div className="resolution-topic">
            <p>Resolution Deadline 07 Mar 2024 11:59 pm</p>
          </div>
          <p className="action-topic">ACTION</p>
          <div className="d-flex align-items-center gap-5">
            <div className="d-flex align-items-center gap-2">
              <img
                src={require("../assets/images/manual.png")}
                className="manual-icon"
                alt=""
              />
              <p className="fs-medium">Manual</p>
            </div>
            <Modal>
              <Modal.ToggleButton>
                <button
                  type="button"
                  className="d-flex align-items-center gap-2"
                >
                  <div onClick={handleButtonClick} className="d-flex align-items-center gap-2">
                  <img
                    src={require("../assets/images/neomagic.png")}
                    className="neomagic-icon"
                    alt=""
                  />
                  <p className="fs-medium" >Neo Magic</p>
                  </div>
                </button>
              </Modal.ToggleButton>
              <Modal.Content >
                <div className="w-100 h-100 d-flex">
                  <div className="btn-section d-flex flex-column gap-4">
                    {[
                      {
                        img: "action",
                        activeImg: "actionactive",
                        state: "actions",
                      },
                      { img: "chat", activeImg: "chatactive", state: "chat" },
                    ].map((item, idx) => {
                      return (
                        <button
                          type="button"
                          key={idx}
                          className={tab1 === item.state ? `active` : ""}
                          onClick={() => setTab1(item.state)}
                        >
                          <img
                            src={require(`../assets/images/${
                              tab1 === item.state ? item.activeImg : item.img
                            }.png`)}
                            alt=""
                          />
                          <p className="fs-medium text-capitalize">
                            {item.state}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                  <div className="modal-main-section d-flex flex-column overflow-auto scroll-bar" ref={ulElement}>
                    <div className="d-flex justify-content-end ">
                      <Modal.CloseIcon>
                        <button type="button" className="close-icon">
                          <img
                            src={require("../assets/images/close.png")}
                            alt=""
                          />
                        </button>
                      </Modal.CloseIcon>
                    </div>
                    {/* {tab1 === "actions" && ( */}
                     <div className={` flex-column h-100 ${tab1 === "actions"?"d-flex":"d-none"}`}>
                     <div className="flex-grow-1 ">
                       <p className="fs-topic-medium text-uppercase topic mb-4">
                         Actions
                       </p>
                       <div className="d-flex flex-column w-100 subject-con">
                         <label htmlFor="subject" className="fs-medium">
                           Subject
                         </label>
                         <input
                           className="w-100 fs-topic-medium"
                           type="text"
                           id="subject"
                           value={`CID ${user?.id} | Action In Progress`}
                           onChange={()=>console.log()}
                         />
                       </div>
                       <ChipCon
                         value={[
                           "Acknowledgement Of Receipt",
                           "Explanation of Issues",
                           "Proposed Next Setps",
                           "Assurance of Service",
                           "Additional Information Request",
                           "Contact Information for Follow-up",
                         ]}
                         setAction={setAction}
                         data={action}
                       />
                       <div className="d-flex align-items-center justify-content-end gap-3 mt-4 cop-img-con">
                           <CopyClipboard copyText={actionContentRef.current}>
                             <button type="button">
                             <img
                               src={require("../assets/images/copy.png")}
                               alt=""
                             />
                             </button>
                           </CopyClipboard>
                         <DropDown>
                           <DropDown.HeaderWithContent>
                             <button type="button">
                               <img
                                 src={require("../assets/images/repeatonhover.png")}
                                 alt=""
                               />
                             </button>
                           </DropDown.HeaderWithContent>
                           <DropDown.BodyWithContent>
                             <ul
                               className={`dropdown-menu custom-drop end-0`}
                             >
                               {actContent.map(
                                 (item, idx) => {
                                   return (
                                     <li key={idx}>
                                       <button
                                         type="button"
                                         className="d-flex align-items-center  px-3 w-100 gap-2"
                                         onClick={() => {setRenderActionContent(actContent[idx])}}
                                       >
                                         <img
                                           src={require(`../assets/images/${item.index}.png`)}
                                           alt=""
                                           className="alignment-icon"
                                         />
                                         <p className="fs-regular text-capitalize">
                                           {item.index}
                                         </p>
                                       </button>
                                     </li>
                                   )
                                 }
                               )}
                             </ul>
                           </DropDown.BodyWithContent>
                         </DropDown>
                         <button type="button" onClick={()=>{demoContentTrigger()}}>
                         <img
                           src={require("../assets/images/edit.png")}
                           alt=""
                         />
                         </button>
                       </div>
                       <div className="d-flex flex-column gap-3 " >
                         <p className="fs-medium">Hi Ahmad,</p>
                          <div className={contentEdit?"editable-content":''} ref={actionContentRef}>
                          <RichTextExample renderActionContent={renderActionContent} contentEdit={contentEdit}></RichTextExample>
                          </div>
                       </div>
                       <div className="d-flex flex-column gap-3 mt-3">
                         <p className="fs-medium grey-text">Best Regards</p>
                         <div className="d-flex align-items-center gap-3 cop-img-con">
                           <LikeDislike/>
                           <CopyClipboard copyText={actionContentRef.current}>
                           <button type="button" className="p-0">
                             <img
                               src={require("../assets/images/copy-1.png")}
                               alt=""
                             />
                           </button>
                           </CopyClipboard>
                           <DownloadText text={actionContentRef.current}>
                           <button type="button" className="p-0">
                             <img
                               src={require("../assets/images/dwnload.png")}
                               alt=""
                             />
                           </button>
                           </DownloadText>
                           <Speech text={actionContentRef.current}>
                            <button type="button" className="p-0">
                             <img
                               src={require("../assets/images/audio.png")}
                               alt=""
                             />
                           </button>
                           </Speech>
                         </div>
                       </div>
                     </div>
                     <div className="footer-con  mt-3">
                       <img
                         src={require("../assets/images/excel.png")}
                         className="excel-img"
                         alt=""
                       />
                       <p className="fs-regular grey-text pb-3">
                         {fileUpload}
                       </p>
                       <div className="bottom-con d-flex align-items-center justify-content-between ">
                         <label htmlFor="upload-file">
                           <input type="file" id="upload-file" onChange={(e)=>{
                              setFileUpload(e.target.files[0].name)
                           }} className="d-none"/>
                           <img
                             src={require("../assets/images/attachment.png")}
                             alt=""
                             className="attach-icon"
                           />
                         </label>
                         <Modal.CloseIcon>
                         <button type="button" className="fs-medium">
                           Submit
                           <img
                             src={require("../assets/images/send.png")}
                             alt=""
                             className="submit-icon"
                           />
                         </button>
                         </Modal.CloseIcon>
                       </div>
                     </div>
                   </div>
                    {/* )} */}
                    {/* {tab1 === "chat" && ( */}
                      <div className={` flex-column h-100 ${tab1 === "chat"?"d-flex":"d-none"}`}>
                        <div className="flex-grow-1 chat-con d-flex flex-column ">
                          <p className="fs-topic-medium text-uppercase topic mb-4">
                            Chat
                          </p>
                          <p className="fs-medium text-center grey-text">
                            Today,3:45pm{" "}
                          </p>
                          <ul className="flex-grow-1 " >
                            {chat.map((item, idx) => {
                              return (
                                <ChatFeature  key={idx}>
                                <li className={item.youSend ? "active" : ""} >
                                  <div
                                    className={`sub-con d-flex align-items-start gap-3 $`}
                                  >
                                    <img
                                      src={require(`../assets/images/${
                                        item.youSend ? "profile" : "chatprofile"
                                      }.png`)}
                                      className="user-img"
                                      alt=""
                                    />
                                    <div className="fs-regular d-flex flex-column gap-2">
                                      <ChatFeature.Content>
                                      {item.msg&&<div className="msg-text">{item.msg}</div>}
                                     {item.img&&<div className="img-preview"><img src={item.img} alt="" /></div>}
                                      </ChatFeature.Content>
                                      <div className="d-flex align-items-center gap-3 cop-img-con ">
                                        <LikeDislike></LikeDislike>
                                        <ChatFeature.CopyClipboard>
                                        <button type="button" className="p-0">
                                          <img
                                            src={require("../assets/images/copy-1.png")}
                                            alt=""
                                          />
                                        </button>
                                        </ChatFeature.CopyClipboard>
                                        <ChatFeature.DownloadText>
                                        <button type="button" className="p-0">
                                          <img
                                            src={require("../assets/images/dwnload.png")}
                                            alt=""
                                          />
                                        </button>
                                        </ChatFeature.DownloadText>
                                        <ChatFeature.Speech>
                                        <button type="button" className="p-0">
                                          <img
                                            src={require("../assets/images/audio.png")}
                                            alt=""
                                          />
                                        </button>
                                        </ChatFeature.Speech>
                                      </div>
                                      {(!item.youSend && idx + 1 === chat.length) ? (
                                        <div className="chat-chip">
                                          <ChipCon
                                            value={[
                                              "Insufficient Funds",
                                              "Incorrect Payment Information",
                                              "Customer Account Flagged",
                                              "Invalid Payee Account",
                                              "Authentication Failure",
                                            ]}
                                            setAction={setDefineChat}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>

                                </ChatFeature>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="footer-con chat-tab-footer">
                          {
                            preview&&<div className="image-preview"><img src={preview} alt="" /></div>
                          }
                          <form className="bottom-con d-flex align-items-center gap-3" onSubmit={(e)=>{
                            chatSubmit(e)}}>
                            <label htmlFor="img-upload">
                              <input type="file" id="img-upload" className="d-none" accept="image/*" onChange={imageUpload}/>
                              <img
                                src={require("../assets/images/addimage.png")}
                                alt=""
                                className="upload-icon"
                              />
                            </label>
                            <button type="button" className="fs-medium">
                              <img
                                src={require("../assets/images/microphone.png")}
                                alt=""
                                className="mic-icon"
                              />
                            </button>
                            <input
                              type="text"
                              className="fs-topic-primary-regular flex-grow-1"
                              placeholder="Enter your prompt"
                              value={userChatType}
                              ref={chatRef}
                              onChange={(e)=>setUserChatType(e.target.value)}
                            />
                            <button type="submit" >
                              <img
                                src={require("../assets/images/send.png")}
                                alt=""
                                className="send-icon"
                              />
                            </button>
                          </form>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
