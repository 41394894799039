import { cloneElement } from "react";

const Speech = ({text,children}) => {
    const speechFunc=()=>{
        let utterance = new SpeechSynthesisUtterance(text?.querySelector("[contenteditable]")?.textContent);
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(utterance);
        utterance.ondata = function(event) {
            console.error('Speech synthesis error:', event.error);
        };
        utterance.onerror = function(event) {
            console.error('Speech synthesis error:', event.error);
        };
        let r = setInterval(() => {
          if (!window.speechSynthesis.speaking) {
            clearInterval(r);
          } else {
            window.speechSynthesis.pause();
            window.speechSynthesis.resume();
          }
        }, 14000);
    }
  return (
    cloneElement(children,{onClick: speechFunc })
  )
}

export default Speech